import { useState, useRef, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft,  } from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../context/CartContext";
import products from "../data/products.json";
import { Product, ProductVariant } from '../types/product-variant';
import { getShopState } from '../context/shopState';
import cartAddSound from '../assets/sounds/Add2Cart.mp3';
import SEO from '../components/SEO';
import ProductSchema from '../components/ProductSchema';
import ProductImages from '../components/product/ProductImages';
import ProductInfo from '../components/product/ProductInfo';
import Lightbox from '../components/product/Lightbox';
import RelatedProducts, { getRelatedProducts } from '../components/product/RelatedProducts';
import { Helmet } from 'react-helmet-async';
import genericPackaging from '/cajas.jpg';
const ProductPage = () => {
    const { slug } = useParams<{ slug: string }>();
    const typedProducts = products as Product[];
    const product = typedProducts.find(p => p.slug === slug) ?? null;
    const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [isAdded, setIsAdded] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const { items, addItem, updateQuantity } = useCart();
    const [showVariantError, setShowVariantError] = useState(false);
    const [audio] = useState(new Audio(cartAddSound));
    
    audio.volume = 0.2;

    const cartItem = items.find(item => 
        item.id === (selectedVariant?.id ?? product?.id)
    );

    const [localQuantity, setLocalQuantity] = useState(cartItem?.quantity || 1);
    const removalTimeout = useRef<NodeJS.Timeout>();

    const activeImages = {
        main: selectedVariant?.images?.main ?? product?.defaultImages?.main ?? product?.mainImage ?? '',
        secondary: selectedVariant?.images?.model ?? product?.defaultImages?.model ?? product?.secondaryImage ?? genericPackaging,
        third: selectedVariant?.images?.zoom ?? product?.defaultImages?.zoom ?? product?.thirdImage ?? genericPackaging
    };

    const lightboxImages = Object.values(activeImages).filter(Boolean);

    const handleAddToCart = () => {
        if (!product) return;

        const isProductInStock = selectedVariant ? selectedVariant.inStock : (product.inStock ?? true);
        if (!isProductInStock) {
            return; // Don't allow adding out-of-stock items
        }

        if (product.variants && product.variants.length > 0 && !selectedVariant) {
            setShowVariantError(true);
            setTimeout(() => {
                setShowVariantError(false);
            }, 3000);
            return;
        }

        const itemToAdd = {
            id: selectedVariant?.id ?? product.id,
            name: `${product.name}${selectedVariant ? ` - ${selectedVariant.material}` : ''}`,
            price: selectedVariant?.price ?? product?.price ?? product?.basePrice ?? 0,
            quantity: 1,
            image: activeImages.main,
            oneOfKind: product.oneOfKind,
            slug: product.slug,
            variantId: selectedVariant?.id ?? null
        };

        if (navigator.vibrate) {
            navigator.vibrate(100);
        }

        try {
            audio.currentTime = 0;
            audio.play().catch(err => console.log('Audio play failed:', err));
        } catch (err) {
            console.log('Audio playback error:', err);
        }

        addItem(itemToAdd);
        setIsAdded(true);
        setShowSuccess(true);
        
        setTimeout(() => {
            setShowSuccess(false);
        }, 2000);
    };

    const handleQuantityChange = (newQuantity: number) => {
        const validQuantity = Math.max(0, newQuantity);
        setLocalQuantity(validQuantity);
        
        if (validQuantity === 0) {
            if (removalTimeout.current) {
                clearTimeout(removalTimeout.current);
            }
            removalTimeout.current = setTimeout(() => {
                if (product) {
                    updateQuantity(product.id, 0);
                    setIsAdded(false);
                }
            }, 800);
        } else {
            if (removalTimeout.current) {
                clearTimeout(removalTimeout.current);
            }
            if (product) {
                updateQuantity(product.id, validQuantity);
            }
        }
    };

    const handleVariantChange = (variantId: string) => {
        const variant = product?.variants?.find(v => v.id === variantId);
        setSelectedVariant(variant || null);
    };

    const getBackToShopUrl = () => {
        const state = getShopState();
        const params = new URLSearchParams();
        
        if (state.filters.sortBy !== 'newest') {
            params.append('sort', state.filters.sortBy);
        }
        if (state.filters.category !== 'all') {
            params.append('category', state.filters.category);
        }
        if (state.filters.collection !== 'all') {
            params.append('collection', state.filters.collection);
        }
        if (state.filters.showOneOfKind !== null) {
            params.append('oneOfKind', state.filters.showOneOfKind.toString());
        }
        if (state.currentPage > 1) {
            params.append('page', state.currentPage.toString());
        }

        const queryString = params.toString();
        return `/shop${queryString ? `?${queryString}` : ''}`;
    };

    const productImageUrl = `https://www.camilaparente.store${selectedVariant?.images?.main ?? product?.defaultImages?.main ?? product?.mainImage}`;
    const modelImageUrl = `https://www.camilaparente.store${selectedVariant?.images?.model ?? product?.defaultImages?.model ?? product?.secondaryImage}`;
    const zoomImageUrl = `https://www.camilaparente.store${selectedVariant?.images?.zoom ?? product?.defaultImages?.zoom ?? product?.thirdImage}`;

    // Create array of all product images with full URLs
    const allProductImages = [productImageUrl, modelImageUrl, zoomImageUrl].filter(Boolean);

    const productSchema = {
        "@context": "https://schema.org",
        "@type": "Product",
        name: product?.name,
        description: product?.description,
        image: allProductImages, // Array of all product image URLs
        url: `https://www.camilaparente.store/product/${product?.slug}`,
        sku: product?.id,
        brand: {
            "@type": "Brand",
            name: "Camila Parente"
        },
        offers: {
            "@type": "Offer",
            url: `https://www.camilaparente.store/product/${product?.slug}`,
            priceCurrency: "EUR",
            price: product?.price,
            itemCondition: "https://schema.org/NewCondition",
            availability: "https://schema.org/InStock"
        }
    };

    useEffect(() => {
        setSelectedVariant(null);
        setSelectedImage(null);
        setIsAdded(false);
        setShowSuccess(false);
        setLocalQuantity(1);
    }, [product?.id]);

    if (!product) {
        return <div className="text-center py-20">Product not found</div>;
    }

    return (
        <>
            <Helmet>
                <title>{product?.name} | Jewelry by Camila Parente</title>
                <meta name="description" content={product?.["SEO-description"]} />
                
                {/* Open Graph / Facebook */}
                <meta property="og:type" content="product" />
                <meta property="og:title" content={product?.name} />
                <meta property="og:description" content={product?.["SEO-description"]} />
                <meta property="og:image" content={productImageUrl} />
                <meta property="og:site_name" content="Jewelry by Camila Parente" />
                
                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={product?.name} />
                <meta name="twitter:description" content={product?.["SEO-description"]} />
                <meta name="twitter:image" content={productImageUrl} />

                {/* Schema.org markup */}
                <script type="application/ld+json">
                    {JSON.stringify(productSchema)}
                </script>
            </Helmet>
            <SEO 
                title={product?.name || 'Product'}
                description={product?.['SEO-description'] || product?.description || ''}
                image={product?.mainImage || ''}
                type="product"
            />
            {product && (
                <ProductSchema 
                    product={product} 
                    currentUrl={window.location.href}
                />
            )}
            <div className="container mx-auto px-4 py-8 max-w-[80vw] mt-2">
                <Link 
                    to={getBackToShopUrl()}
                    className="inline-flex items-center gap-2 mb-6 text-black dark:text-white hover:text-brand-blue dark:hover:text-brand-blue transition-colors"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="h-4 w-4" />
                    <span>Back to Shop</span>
                </Link>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <ProductImages
                        product={product}
                        selectedVariant={selectedVariant}
                        onImageClick={setSelectedImage}
                    />

                    <ProductInfo
                        product={product}
                        selectedVariant={selectedVariant}
                        isAdded={isAdded}
                        localQuantity={localQuantity}
                        onAddToCart={handleAddToCart}
                        onQuantityChange={handleQuantityChange}
                        showVariantError={showVariantError}
                        onVariantChange={handleVariantChange}
                    />
                </div>

                <Lightbox
                    selectedImage={selectedImage}
                    images={lightboxImages}
                    onClose={() => setSelectedImage(null)}
                />

                {/* Success message */}
                <AnimatePresence>
                    {showSuccess && (
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 50 }}
                            className="fixed bottom-4 right-4 bg-brand-blue text-white px-4 py-2 rounded-lg shadow-lg"
                        >
                            Item added to cart!
                        </motion.div>
                    )}
                </AnimatePresence>

                {product && (
                    <div className="relative">
                        <RelatedProducts 
                            products={getRelatedProducts(product, typedProducts, items)}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ProductPage;