import { Product, ProductVariant } from '../../types/product-variant';
import genericPackaging from '/cajas.jpg';

interface ProductImagesProps {
    product: Product;
    selectedVariant: ProductVariant | null;
    onImageClick: (imageSrc: string) => void;
}

interface ActiveImages {
    main: string;
    secondary: string;
    third: string;
}

const ProductImages = ({ product, selectedVariant, onImageClick }: ProductImagesProps) => {
    const activeImages: ActiveImages = {
        main: selectedVariant?.images?.main ?? product?.defaultImages?.main ?? product?.mainImage ?? '',
        secondary: selectedVariant?.images?.model ?? product?.defaultImages?.model ?? product?.secondaryImage ?? genericPackaging,
        third: selectedVariant?.images?.zoom ?? product?.defaultImages?.zoom ?? product?.thirdImage ?? genericPackaging
    };

    return (
        <div className="space-y-4">
            {/* Main Image */}
            <img 
                src={activeImages.main}
                alt={product?.name} 
                className="w-full h-auto rounded-lg shadow-lg cursor-pointer hover:opacity-75 transition-opacity"
                onClick={() => onImageClick(activeImages.main)}
                width={800}
                height={800}
            />
            {/* Secondary Images */}
            <div className="hidden md:grid grid-cols-2 gap-4">
                <img 
                    src={activeImages.secondary}
                    alt={`${product?.name} secondary`} 
                    className="w-full h-48 object-cover rounded-lg shadow-md cursor-pointer hover:opacity-75 transition-opacity"
                    onClick={() => onImageClick(activeImages.secondary)}
                    loading="lazy"
                    width={400}
                    height={192}
                />
                <img 
                    src={activeImages.third}
                    alt={`${product?.name} third`} 
                    className="w-full h-48 object-cover rounded-lg shadow-md cursor-pointer hover:opacity-75 transition-opacity"
                    onClick={() => onImageClick(activeImages.third)}
                    loading="lazy"
                    width={400}
                    height={192}
                />
            </div>

            {/* Secondary Images - Mobile Only */}
            <div className="md:hidden grid grid-cols-2 gap-4">
                <img 
                    src={activeImages.secondary}
                    alt={`${product?.name} secondary`} 
                    className="w-full h-48 object-cover rounded-lg shadow-md cursor-pointer hover:opacity-75 transition-opacity"
                    onClick={() => onImageClick(activeImages.secondary)}
                    loading="lazy"
                    width={300}
                    height={192}
                />
                <img 
                    src={activeImages.third}
                    alt={`${product?.name} third`} 
                    className="w-full h-48 object-cover rounded-lg shadow-md cursor-pointer hover:opacity-75 transition-opacity"
                    onClick={() => onImageClick(activeImages.third)}
                    loading="lazy"
                    width={300}
                    height={192}
                />
            </div>
        </div>
    );
};

export default ProductImages; 