import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Success: React.FC = () => {
  const { clearCart, items } = useCart();
  const [isClearing, setIsClearing] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get('session_id');
  const [customerEmail, setCustomerEmail] = useState<string | null>(null);

  // First get the session details and send email
  useEffect(() => {
    const processOrder = async () => {
      if (!session_id || emailSent) return;

      try {
        // Get session details
        const response = await fetch(`/api/get-session?session_id=${session_id}`);
        const data = await response.json();
        setCustomerEmail(data.customerEmail);

        if (data.customerEmail && items.length > 0) {
          // Send confirmation email
          const emailResponse = await fetch('/api/send-order-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              customerEmail: data.customerEmail,
              orderDetails: {
                items,
                total: items.reduce((sum, item) => sum + (item.price * item.quantity), 0)
              }
            }),
          });

          if (emailResponse.ok) {
            setEmailSent(true);
            setIsClearing(true);
          }
        }
      } catch (error) {
        console.error('Error processing order:', error);
      }
    };

    processOrder();
  }, [session_id, items]);

  // Only clear cart after email is sent
  useEffect(() => {
    if (isClearing && emailSent) {
      const timer = setTimeout(() => {
        clearCart();
        setIsClearing(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [isClearing, emailSent, clearCart]);

  return (
    <div className="bg-brand-sand dark:bg-opacity-0 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white dark:bg-brand-charcoal rounded-lg shadow-lg p-8 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <FontAwesomeIcon 
            icon={faCheckCircle} 
            className="text-green-500 text-5xl mb-6"
          />
          <h1 className="text-3xl font-bold text-brand-dark dark:text-white mb-4">
            Thank you for your order!
          </h1>
          <p className="text-gray-600 mb-6 dark:text-white">
            {emailSent 
              ? "We've sent you a confirmation email with your order details."
              : "We're processing your order..."}
          </p>
          <Link
            to="/shop"
            className="inline-block bg-brand-blue text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Continue Shopping
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Success;